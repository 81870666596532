//ferpi prod///////////
const ferpi_url = 'https://ferpi.tsnqb.uz/api/';
// const ferpi_url = 'http://localhost:3002/';
export const environment = {
  otm: 'ferpi',

  production: true,
  http_auth: '5KWBffF64Q7v6J9fLA4K',
  otm_name: "Ta'lim sifatini nazorat qilish bo'limi",
  firebaseConfig: {
    // pvorey
    apiKey: 'AIzaSyBmzKSP6a7bHTJWRZTqRgF3u8ucSJB2_fg',
    authDomain: 'pvorey.firebaseapp.com',
    databaseURL:
      'https://pvorey-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'pvorey',
    storageBucket: 'pvorey.appspot.com',
    messagingSenderId: '975847085157',
    appId: '1:975847085157:web:5008868ba373c480ffa28c',
    measurementId: 'G-GRE7FMXH99',
  },
  http: {
    socket: {
      path: 'ws://ferpi.tsnqb.uz/socket.io',
    },
    editArea: {
      path: ferpi_url + 'system/area',
    },
    addArea: {
      path: ferpi_url + 'system/area',
    },
    deleteArea: {
      path: ferpi_url + 'system/area',
    },
    removeDepartment: {
      path: ferpi_url + 'system/department',
    },
    editDepartment: {
      path: ferpi_url + 'system/department',
    },
    getDepartmentAArea: {
      path: ferpi_url + 'system/getAll',
    },
    addDepartment: {
      path: ferpi_url + 'system/department',
    },
    userRegister: {
      path: ferpi_url + 'auth/registration',
    },
    recoverPass: {
      path: ferpi_url + 'mailing/send-mail',
    },
    allRoles: {
      path: ferpi_url + 'auth/login',
    },
    removeRole: {
      path: ferpi_url + 'auth/login',
    },
    setRole: {
      path: ferpi_url + 'auth/login',
    },
    removeTeacher: {
      path: ferpi_url + 'auth/login',
    },
    allUsers: {
      path: ferpi_url + 'users/all',
    },
    updateUser: {
      path: ferpi_url + 'auth/login',
    },
    deleteUser: {
      path: ferpi_url + 'auth/login',
    },
    login: {
      path: ferpi_url + 'auth/login',
    },
    autologin: {
      path: ferpi_url + 'auth/autologin',
    },
    updateIndex: {
      path: ferpi_url + 'index/update',
    },
    rateIndex: {
      path: ferpi_url + 'index/rate',
    },
    get: {
      path: 'https://ferpi.tsnqb.uz/db/2024/get_data.php',
      file: 'pvorey.txt',
    },
    delete_only_inform_file: {
      path: 'https://ferpi.tsnqb.uz/db/2024/delete_only_inform_file.php',
    },
    getFieldsInform: {
      path: 'https://ferpi.tsnqb.uz/db/2024/get_fields_inform.php',
    },
    setInc: {
      path: 'https://ferpi.tsnqb.uz/db/2024/set_data_inc.php',
      file: 'pvorey.txt',
    },
    del: {
      path: 'https://ferpi.tsnqb.uz/db/2024/del_data.php',
      file: 'pvorey.txt',
    },

    setIncDelUpd: {
      path: 'https://ferpi.tsnqb.uz/db/2024/set_inc_del_upd.php',
      file: 'pvorey.txt',
    },
    remDocDownGrade: {
      path: 'https://ferpi.tsnqb.uz/db/2024/rem_doc_with_down_grade.php',
    },
    googleScholarCited: {
      path: 'https://ferpi.tsnqb.uz/db/2024/google_scholar.php',
    },
    googleScholarCitedAll: {
      path: 'https://ferpi.tsnqb.uz/db/2024/google_scholar_all.php',
    },
    setNewPvo: {
      path: 'https://ferpi.tsnqb.uz/db/2024/set_new_pvo.php',
    },
    removePvo: {
      path: 'https://ferpi.tsnqb.uz/db/2024/remove_pvo.php',
    },
    controlIndexStatus: {
      path: 'https://ferpi.tsnqb.uz/db/2024/control_index_status.php',
    },
    createAnnounce: {
      path: 'https://ferpi.tsnqb.uz/db/2024/create_announce.php',
    },
    getAnnounces: {
      path: 'https://ferpi.tsnqb.uz/db/2024/get_announces.php',
    },
    removeAnnounce: {
      path: 'https://ferpi.tsnqb.uz/db/2024/remove_announce.php',
    },
    editUserAccaunt: {
      path: 'https://ferpi.tsnqb.uz/db/2024/edit_user_account.php',
    },
    createIndex: {
      path: 'https://ferpi.tsnqb.uz/db/2024/create_index.php',
    },
  },
  tizim: [
    {
      fakultet: 'Mexanika - mashinasozlik',
      kafedralar: [
        'Mashinasozlik texnologiyasi, jihozlari va mashinasozlikda ishlab chiqarishni avtomatlashtirish',
        'Oliy matematika',
        'Tadbiqiy mexanika',
        'Texnologik mashinalar va jihozlar',
        'Yer usti transport tizimlari va ularni ekspluatatsiyasi',
      ],
    },
    {
      fakultet: 'Energetika',
      kafedralar: [
        'Elektr energetika',
        'Elektr texnikasi, elektr mexanikasi va elektr texnologiyalari',
        'Fizika',
        'Chizma geometriya va muhandislik grafikasi',
      ],
    },
    {
      fakultet: 'Kimyo texnologiya',
      kafedralar: [
        'Kimyo va kimyoviy texnologiya',
        'Neft va neft-gazni qayta ishlash texnologiyasi',
        'Oziq-ovqatlar texnologiyasi',
      ],
    },
    {
      fakultet: 'Qurilish',
      kafedralar: [
        'Bino va inshootlar qurilishi',
        'Muhandislik kommunikatsiyalari qurilishi va montaji',
        'Geodeziya, kartografiya va kadastr',
        'Qurilish materiallari, buyumlari va konstruksiyalarini ishlab chiqarish',
        'Arxitektura',
      ],
    },
    {
      fakultet: 'Ishlab chiqarishda boshqaruv',
      kafedralar: [
        'Iqtisodiyot',
        'Menejment',
        "Tillarni o'rgatish",
        'Buxgalteriya xisobi va audit',
        'O‘zbek tili va tillarni o‘rgatish',
      ],
    },
    {
      fakultet: "Yengil sanoat va to'qimachilik",
      kafedralar: [
        'Yengil sanoat buyumlari texnologiyasi',
        'Qishloq xo‘jalik mahsulotlarini saqlash va dastlabki ishlash texnologiyasi',
        'Tabiiy tolalar',
      ],
    },
    {
      fakultet: 'Kompyuterlashgan loyihalash tizimlari',
      kafedralar: [
        'Elektronika va asbobsozlik',
        'Intellektual muhandislik tizimlari',
        'Metrologiya standartlashtirish va maxsulot sifati menejmenti',
        'Ijtimoiy fanlar va sport',
      ],
    },
  ],
  grastud: 556,
  fakultet: [
    'Mexanika - mashinasozlik',
    'Energetika',
    'Kimyo texnologiya',
    'Qurilish',
    'Ishlab chiqarishda boshqaruv',
    "Yengil sanoat va to'qimachilik",
    'Kompyuterlashgan loyihalash tizimlari',
  ],
  kafedra: [
    'Mashinasozlik texnologiyasi, jihozlari va mashinasozlikda ishlab chiqarishni avtomatlashtirish',
    'Oliy matematika',
    'Tadbiqiy mexanika',
    'Texnologik mashinalar va jihozlar',
    'Yer usti transport tizimlari va ularni ekspluatatsiyasi',
    'Elektr energetika',
    'Elektr texnikasi, elektr mexanikasi va elektr texnologiyalari',
    'Fizika',
    'Chizma geometriya va muhandislik grafikasi',
    'Kimyo va kimyoviy texnologiya',
    'Neft va neft-gazni qayta ishlash texnologiyasi',
    'Oziq-ovqatlar texnologiyasi',
    'Bino va inshootlar qurilishi',
    'Muhandislik kommunikatsiyalari qurilishi va montaji',
    'Geodeziya, kartografiya va kadastr',
    'Qurilish materiallari, buyumlari va konstruksiyalarini ishlab chiqarish',
    'Arxitektura',
    'Iqtisodiyot',
    'Menejment',
    "Tillarni o'rgatish",
    'Buxgalteriya xisobi va audit',
    'O‘zbek tili va tillarni o‘rgatish',
    'Yengil sanoat buyumlari texnologiyasi',
    'Qishloq xo‘jalik mahsulotlarini saqlash va dastlabki ishlash texnologiyasi',
    'Tabiiy tolalar',
    'Elektronika va asbobsozlik',
    'Intellektual muhandislik tizimlari',
    'Metrologiya standartlashtirish va maxsulot sifati menejmenti',
    'Ijtimoiy fanlar va sport',
  ],
};
